function buildFormData(formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach(key => {
        buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
}

function objectToFormData(data) {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
}


function preloadImage(image) {
    let img = new Image();
        img.src = image;

    return img;
}

function updateQueryString( queryParams ) {
    let updatedUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;

    let urlParams = new URLSearchParams(window.location.search);

    for (const key in queryParams) {
      if ( queryParams[key] === '' || queryParams[key] === null ) {
        urlParams.delete(key);
      } else {
        urlParams.set(key, queryParams[key]);
      }
    }

    if ( urlParams.toString() !== '' ) {
      updatedUrl += '?';
    }

    window.location = updatedUrl + urlParams.toString();
}

window.updateQueryString = updateQueryString;

window.objectToFormData = objectToFormData;
window.preloadImage = preloadImage;
window.updateQueryString = updateQueryString;
