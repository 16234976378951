/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


require('./bootstrap');

require('./helpers');

$( document ).ready(function() {
    
    
    setTimeout(function(){
        $('#success-msg').css({"top": "0"});
    });
    setTimeout(function(){
        $('#success-msg').css({"top": "-70px"});
    }, 3000);

    setTimeout(function(){
        $('.pass-reset-msg').fadeOut( "slow" );
    }, 4000);

    //user navigation clickout fix
    userNavigation();
    $(window).resize(function() {
        userNavigation();
    });

    function userNavigation() {
        if ($(window).width() >= 960) {
            $('.user-ddown').removeClass('open');
        }
    }










    hide = true;
    $('body').on("click", function () {
        if (hide) $('.nav-user').removeClass('nav-user--active');
        if (hide) $('#app-links').removeClass('active');
        hide = true;
    });
    $('body').on('click', '.openUser', function () {    
        if ($('.nav-user').hasClass('nav-user--active')) {
            $('.nav-user').removeClass('nav-user--active');
            return false;
        }
        $('.nav-user').toggleClass('nav-user--active');
        $('#app-links').removeClass('active');
        hide = false;
    });
    $('body').on('click', '#menu-toggle', function () {    
        if ($('#app-links').hasClass('active')) {
            $('#app-links').removeClass('active');
            return false;
        }
        $('#app-links').toggleClass('active');
        $('.nav-user').removeClass('nav-user--active');
        hide = false;
    });
    $('body').on('click', '.nav-user', function () {
        if ($('.nav-user').hasClass('nav-user--active')) {
            $('.nav-user').addClass('nav-user--active');
            return false;
        }
    });
    $('body').on('click', '#app-links', function () {
        if ($('#app-links').hasClass('active')) {
            $('#app-links').addClass('active');
            return false;
        }
    });
    $('body').on('click', '.nav-user a, #app-links a', function () {
        preventDefault;
    });

    $('.app-links-close').click(
        function() {
            $('#app-links').removeClass('active');
        }
    );

    $('.slidein-box-trigger').click(
        function() {
            $('#slidein-box').addClass('active');
            $('#page-overlay').addClass('active');
        }
    );

    $('.slidein-box-close-button').click(
        function() {
            $('.slide-in-box').removeClass('active');
            $('#page-overlay').removeClass('active');
            location.reload();
        }
    );
    
    $('.slidein-box-trigger-edit').click(
        function() {
            $('#slidein-box-edit').addClass('active');
            $('#page-overlay').addClass('active');            
        }
    );

    $('.slidein-box-trigger-create').click(
        function() {
            $('#slidein-box-create').addClass('active');
            $('#page-overlay').addClass('active');
        }
    );

    $('.slidein-box-trigger-project-create').click(
        function() {
            $('#slidein-box-project-create').addClass('active');
            $('#page-overlay').addClass('active');
        }
    );

    $('.slidein-box-trigger-project-edit').click(
        function() {
            $('#slidein-box-project-edit').addClass('active');
            $('#page-overlay').addClass('active');
        }
    );
    

    $('.slide-in-box-scale').click(
        function() {
            $(this).parent().parent().parent().parent().toggleClass('full-screen');
        }
    );

    $('.dropdown-arrow').click(
        function() {
            $(this).parent().siblings().removeClass('open-menu');
            $(this).parent().toggleClass('open-menu');
    });

    $('#sidebar-toggle').click(
        function() {
            $('#app').toggleClass('sidebar-small');
            $('#grid-view').toggleClass('active');
            $('#full-view').toggleClass('active');
        }
    );
    $('#full-view').click(
        function() {
            $(this).addClass('active');
            $('#grid-view').removeClass('active');
            $('#app').addClass('full-view sidebar-small');
        }
    );
    $('#grid-view').click(
        function() {
            $(this).addClass('active');
            $('#full-view').removeClass('active');
            $('#app').removeClass('full-view sidebar-small');
        }
    );

    $('#main-content .sidebar-wrap .sidebar ul li > a').hover(
        function() {
            $(this).toggleClass('hovered');
            $(this).siblings('.dropdown-arrow').toggleClass('hovered');
        }
    );

    $('.dropdown-arrow').hover(
        function() {
            $(this).toggleClass('hovered');
            $(this).siblings('a').toggleClass('hovered');
        }
    );

    if (document.referrer.indexOf('?') > 0){
        if (document.referrer.substring(0,document.referrer.indexOf('?')).toLowerCase().indexOf('login') >= 0){
            $('#welcome__msg').addClass('active');
            setTimeout(function() {
                $('#welcome__msg').removeClass("active");
            }, 3000);
        }
    } else {
        if (document.referrer.toLowerCase().indexOf('login') > 0){
            $('#welcome__msg').addClass('active');
            setTimeout(function() {
                $('#welcome__msg').removeClass("active");
            }, 3000);
        }
    }
     
    

});
